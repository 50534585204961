import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDvC56wwg9MXN8xApKctvDVDVvbAqFLXgE",
  authDomain: "webardemo-4ce4c.firebaseapp.com",
  databaseURL: "https://webardemo-4ce4c.firebaseio.com",
  projectId: "webardemo-4ce4c",
  storageBucket: "webardemo-4ce4c.appspot.com",
  messagingSenderId: "1070404004604",
  appId: "1:1070404004604:web:6a090ae270c3bd6cda8579",
  measurementId: "G-Y6JY2Y9H1Y"
};

// Initialize Fireba  ses
firebase.initializeApp(firebaseConfig);
firebase.analytics();


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
