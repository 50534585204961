import React from 'react';
import FbStorage from "./FbStorage";

class Slide extends React.Component {



  constructor(props) {
    super(props);

    this.state = {
      bgImage: null,
    }

    this.getBgImageUrl = this.getBgImageUrl.bind(this);

  }

  getId(){
    return this.props.id;
  }


  getBgImageUrl(){
    let folder= this.props.id.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    let name = this.props.id.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toLowerCase())));
    let filepath = '/models/'+folder+'/'+name+'.png';

    new FbStorage().getUrl(filepath).then((url)=>{
      this.setState({ bgImage: url });
    }).catch(function(error) {
      console.log(error);
    });
  }

  componentDidMount() {
    this.getBgImageUrl();
  }


  render(){
    return(
        <button
          id={this.props.id}
          style={{backgroundImage: `url(${this.state.bgImage})`}}
          onClick={this.props.onClick}
          className="slide">
        </button>
    );
  }
}

export default Slide;
