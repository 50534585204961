import React from 'react';

class Header extends React.Component {

  render() {
    return(
      <div className="vendor">
        <a target="_blank" href="https://www.octopus.cl/" rel="noreferrer">
          <img className="logo" src="/images/logo.png" alt="Octopus"/>
        </a>
      </div>
    )
  }
}

export default Header;
