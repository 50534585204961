import React from 'react';
import Header from './Header';
import './ModelViewer.css';

import Slider from './Slider';
import FbStorage from "./FbStorage";


import firebase from "firebase/app";
import "firebase/firestore";

class ModelViewer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        url: null,
        iosurl: null,
      }
      this.setModelUrls = this.setModelUrls.bind(this);
    }


    componentDidMount () {
      const script1 = document.createElement("script");
      script1.src = "https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js";
      script1.async = true;
      document.body.appendChild(script1);
      const script2 = document.createElement("script");
      script2.src = "https://unpkg.com/@google/model-viewer/dist/model-viewer-legacy.js";
      script1.async = true;
      document.body.appendChild(script2);

      //  INIT
      const db = firebase.firestore();
      db.collection("config").doc("models").get().then((doc)=>{
        this.setModelUrls(doc.data().name[0]);
      });
    }

    setModelUrls(name){
      let folder= name.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
      let filename= name.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toLowerCase())));
      let modelpath = '/models/'+folder+'/'+filename+'.glb';

      new FbStorage().getUrl(modelpath).then((url)=>{
        var iosurl = url.replace(".glb", ".usdz");
        this.setState({ url: url });
        this.setState({ iosurl: iosurl });
      }).catch(function(error) {
        console.log(error);
      });

    }

    render() {
      return(
        <div className="demo">
          <Header/>
          <model-viewer
            id="modelo"
            src={this.state.url}
            ar_preferred
            autoplay
            ar
            ar-modes="webxr scene-viewer quick-look"
            ar-scale="auto"
            camera-controls
            alt="Octopus WebAR"
            skybox-image=""
            ios-src={this.state.iosurl}
            shadow-intensity="1"
            auto-rotate>

            <button slot="ar-button" id="ar-button">
              Ver en tu espacio
            </button>

            <div id="ar-prompt">
              <img src="/images/hand.png" alt="Octopus"/>
            </div>

            <Slider
              parentCallback={this.setModelUrls}
            />

          </model-viewer>
        </div>
      );
    }
}

export default ModelViewer;
