import React from 'react';

import firebase from "firebase/app";
import "firebase/storage";

class FbStorage extends React.Component {
  constructor(props) {
    super(props);
    this.getUrl = this.getUrl.bind(this);
  }

  async getUrl(name) {
    return await firebase.storage().ref(name).getDownloadURL().then(function(url) {
        return url;
      })
      .catch(function(error) {
        console.log(error);
      });

  }

}
export default FbStorage;
