import React from 'react';
import Slide from './Slide';

import firebase from "firebase/app";
import "firebase/firestore";

class Slider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modelList: []
    }

    this.switchSource = this.switchSource.bind(this);
  }

  componentDidMount(){
    const db = firebase.firestore();
    db.collection("config").doc("models").get().then((doc)=>{
      const data = doc.data();
        this.setState({modelList: data.name});
    });
  }


  switchSource(e) {
    e.preventDefault();
    let name = e.target.id.toString().toLowerCase();
    this.props.parentCallback(name);

    const slides = document.querySelectorAll(".slide");;
    slides.forEach((element) => {
      element.classList.remove("selected");
    });
    e.target.classList.add("selected");
  }

  render() {
    return (
      <div className="slider">
        <div id="slides" className="slides">
          {
            this.state.modelList.map(id =>
            <Slide id={id.toLowerCase()} onClick={this.switchSource}/>
          )}
        </div>
    </div>);
  }
}

export default Slider;
